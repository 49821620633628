export const CURRENCY_BY_LOCALE = {
  co: 'COP',
  mx: 'MXN'
}

export const convertNumberToWord = (number, convertTo, numDecimals, formatter) => {
  switch (convertTo) {
  case 'thousands':
    return convertToThousands(number, numDecimals, formatter)
  case 'millions':
    return convertToMillions(number, numDecimals, formatter)
  default:
    break
  }
}

export const convertToThousands = (number, numDecimals, formatter) => {
  const thousands = number / (10 ** 3)
  const formatted = formatter(thousands, 'currency', { maximumFractionDigits: numDecimals })
  return `${formatted} mil`
}

export const convertToMillions = (number, numDecimals, formatter) => {
  const millions = number / (10 ** 6)
  const formatted = formatter(millions, 'currency', { maximumFractionDigits: numDecimals })
  return `${formatted} millones`
}

export const parseBigNumberToSimplifiedCurrency = (value, locale) => {
  if (!value) return ''

  const valueInMillions = (value / 1_000_000).toFixed(1)

  return {
    co: `$${valueInMillions}Mill`,
    mx: `$${valueInMillions}MDP`
  }[locale] || ''
}
